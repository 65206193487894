@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Graphik';
  src: url('../public/fonts/graphik/GraphikExtralight.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/fonts/graphik/GraphikThin.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/fonts/graphik/GraphikRegular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/fonts/graphik/GraphikMedium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/fonts/graphik/GraphikSemibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/fonts/graphik/GraphikBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/fonts/graphik/GraphikBold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('../public/fonts/graphik/GraphikBlack.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

body{
  font-family: 'Graphik';
}


.mainHeading {
  @apply text-[54px] font-[700];
}
.description {
  @apply font-normal text-[20px];
}
.blueGradient {
  @apply bg-gradient-to-b from-blue-gradient via-blue-transparent to-transparent;
}
.btnPrimary {
  @apply bg-primary text-white px-[24px] py-[9px] text-[18px] rounded-[3px];
}
.btnPrimaryHeader {
  @apply bg-primary text-white px-[24px] py-[9px] text-[14px] rounded-[3px];
}
.boxedGrid {
  @apply border border-[#E9E9E9] px-8 py-[45px] sm:w-[calc(33%-12px)] w-[100%] ;
}
/*  */
/* width */
.customScroll::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.customScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.customScroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

/* Handle on hover */
.customScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* paginate */
.paginateReact ul {
  display: flex;
  color: #6d6d6d;
  justify-content: center;
  flex-wrap: wrap;
}
.paginateReact li {
  margin: 0 20px;
  padding: 0 8px;
}
.paginateReact li.selected {
  background: #0040df;
  color: #fff;
  border-radius: 50px;
}
ul.slick-dots {
  top: 30px;
  right: 60px;
  left: auto;
  text-align: right;
  width: auto;
}
.slick-dots li {
  width: 7px;
}
.slick-dots li button:before {
  display: none;
}
.slick-dots li button {
  background: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  width: 4px;
  height: 4px;
}
.slick-dots li.slick-active button {
  width: 6px;
  height: 6px;
  background: #d9d9d9;
}

  .subtitle{
    color: #666;
    line-height: 1.8em;
    margin: 1em 0;
    font-size: 18px !important;
  }

  .bannerTitle{
    margin-top: 100px;
  }


  .partnerDescription{
    font-size: 16px;
    line-height: 1.8em;
    margin: 1em 0;
  }

  .addressElipsis{
    max-height: 78px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    }
    .headingEllipsis{
      height: 38px;
      display: flex;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      align-items: center;
    }
    @media(max-width:768px){
      .slick-next{
        right: 0;
      }
    }
    .successAlert{
      color: green;
      text-align: right;
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 100;
      left: 0;
      background: #c8f8c8;
      display: inline-block;
      width: auto;
      padding: 5px 17px;
      left: 50%;
      transform: translateX(-50%);
      margin: 14px 0;
      border-radius: 7px;
    }
    .errorAlert{
      color: red;
      text-align: right;
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 100;
      left: 0;
      background: #ffdfdf;
      display: inline-block;
      width: auto;
      padding: 5px 17px;
      left: 50%;
      transform: translateX(-50%);
      margin: 14px 0;
      border-radius: 7px;
    }

    input.hideStepper::-webkit-outer-spin-button,
input.hideStepper::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}