.modal-base {
    top: 5%;
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 35%;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8);
  }
  
  .modal-base_after-open {
    opacity: 1;
    transform: scale(1);
  }
  
  .modal-base_before-close {
    transform: scale(.2);
    opacity: 0;
  }
  
  .overlay-base {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(66, 66, 66, .5);
    opacity: 0;
      transition: all 0.3s ease-out;
      z-index: 5;
  }
  
  .overlay-base_after-open {
    opacity: 1;
  }
  
  .overlay-base_before-close {
    opacity: 0;
  }